import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCE73o0gRuGV9abszMqBBEyWbLeOagW0xM",
    authDomain: "swastik-deb8e.firebaseapp.com",
    projectId: "swastik-deb8e",
    storageBucket: "swastik-deb8e.appspot.com",
    messagingSenderId: "738699926112",
    appId: "1:738699926112:web:721d7842703b0ae7278105",
    measurementId: "G-NMZV8D1C0Y"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const storage = getStorage(firebaseApp);

// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
    prompt: "select_account "
});

export const auth = getAuth(firebaseApp);

export const signInWithGooglePopup = () => signInWithPopup(auth, provider)
    .then((result) => {
        return result
    })
    .catch((error) => {
        return error
    });
