import { lazy } from 'react';

const Login = lazy(() => import('../pages/auth/login'));
const ForgotPassword = lazy(() => import('../pages/auth/forgotPassword'));
const CheckMail = lazy(() => import('../pages/auth/openMail'));
const ResetPassword = lazy(() => import('../pages/auth/resetPassword'));
const Success = lazy(() => import('../pages/auth/success'));
const Dashboard = lazy(() => import('../pages/dashboard'));
const AdminUsers = lazy(() => import('../pages/adminUsers'));
const AddUser = lazy(() => import('../pages/adminUsers/addUser'));
const ViewAdminUser = lazy(() => import('../pages/adminUsers/viewUser'));
const Reports = lazy(() => import('../pages/reports'));
const DailyCostOpByTalleyReport = lazy(() => import('../pages/reports/dailyCostOpByTally'));
const DailyCostCompByShowReport = lazy(() => import('../pages/reports/dailyCostCompByShow'));
const WeeklyCostCompByShowReport = lazy(() => import('../pages/reports/weeklyCostCompByShow'));
const DailyCostCompByVendorReports = lazy(() => import('../pages/reports/dailyCostCompByVendors'));
const VcUsers = lazy(() => import('../pages/allPersonals/vcUser'));
const AddVcUsers = lazy(() => import('../pages/allPersonals/vcUser/addUser'));
const EcUsers = lazy(() => import('../pages/allPersonals/ecUser'));
const AddEcUsers = lazy(() => import('../pages/allPersonals/ecUser/addEcUser'));
const FcUsers = lazy(() => import('../pages/allPersonals/fcUser'));
const AddFcUsers = lazy(() => import('../pages/allPersonals/fcUser/addFcUser'));
const RaiseNewRequest = lazy(() => import('../pages/dashboard/raiseNewRequest'));
const Roles = lazy(() => import('../pages/roles'));
const AddRole = lazy(() => import('../pages/roles/addRole'));
const ManageChannel = lazy(() => import('../pages/project/channel'));
const AddChannel = lazy(() => import('../pages/project/channel/addChannel'));
const VfxMaster = lazy(() => import('../pages/project/vfx'));
const AddVfx = lazy(() => import('../pages/project/vfx/addVfx'));
const ApproveNewRequest = lazy(() => import('../pages/dashboard/requestApprove'));
const ManageSerials = lazy(() => import('../pages/project/serials'));
const AddProject = lazy(() => import('../pages/project/serials/addProject'));
const ProductMaster = lazy(() => import('../pages/productMaster'));
const AddProductMaster = lazy(() => import('../pages/productMaster/addProduct'));
const AddCategory = lazy(() => import('../pages/productMaster/addcategory'));
const ManageUnits = lazy(() => import('../pages/productMaster/manageUnits'));
const AddUnit = lazy(() => import('../pages/productMaster/manageUnits/addUnit'));
const ManageLocation = lazy(() => import('../pages/manageLocation'));
const AddLocation = lazy(() => import('../pages/manageLocation/addLocation'));
const ViewLocation = lazy(() => import('../pages/manageLocation/viewLocation'));
const AllVendor = lazy(() => import('../pages/vendor/allVendor'));
const ViewVendor = lazy(() => import('../pages/vendor/allVendor/viewVendor'));
const AddVendor = lazy(() => import('../pages/vendor/allVendor/addVendor'));
const ManageSchedule = lazy(() => import('../pages/schedule/manageSchedules'));
const ScheduleDetails = lazy(() => import('../pages/schedule/manageSchedules/viewSchedule'));
const ProjectDetails = lazy(() => import('../pages/project/serials/viewProject'));
const OneTimeCost = lazy(() => import('../pages/project/serials/oneTimeCost'));
const AddFcSerial = lazy(() => import('../pages/project/serials/addFc'));
const EpisodicListing = lazy(() => import('../pages/project/serials/episodicListing'));
const EpisodicListAdd = lazy(() => import('../pages/project/serials/episodicListing/add'));
const AssignEmployee = lazy(() => import('../pages/project/serials/episodicListing/assignEmployee'));
const AddSchedule = lazy(() => import('../pages/schedule/manageSchedules/addSchedule'));
const ProductRequisition = lazy(() => import('../pages/productRequisition'));
const RaiseNewProductRequisition = lazy(() => import('../pages/productRequisition/raiseNewRequest'));
const ApproveProductRequisition = lazy(() => import('../pages/productRequisition/requestApprove'));
const ManageUnitSchedule = lazy(() => import('../pages/schedule/manageUnits'));
const AddUnitSchedule = lazy(() => import('../pages/schedule/manageUnits/addUnit'));
const PlannedReport = lazy(() => import('../pages/schedule/manageSchedules/plannedReport'));
const ActualReport = lazy(() => import('../pages/schedule/manageSchedules/actualReport'));
const FinalCostReport = lazy(() => import('../pages/schedule/manageSchedules/finalCostReport'));
const Attendance = lazy(() => import('../pages/schedule/manageSchedules/attendance'));
const UserProfile = lazy(() => import('../pages/userProfile'));
const ViewLockFc = lazy(() => import('../pages/project/serials/viewLockFc'));
const Resources = lazy(() => import('../pages/resources'));
const CostComparisonReport = lazy(() => import('../pages/reports/costComparison'));
const PlannedActualVarience = lazy(() => import('../pages/reports/plannedActualVarience'));
const PlannedDeliveredVarience = lazy(() => import('../pages/reports/plannedDeliveredVarience'));
const DailyComparisonDetailed = lazy(() => import('../pages/reports/dailyComparisonDetailed'));
const DailyComparisonWip = lazy(() => import('../pages/reports/dailycostVarienceWip'));
const MonthlyCostCompByVendor = lazy(() => import('../pages/reports/monthlyCostCompByVendor'));



export const PrivateRouteComponents = [
    {
        path: "*",
        component: Dashboard
    },
    {
        path: "/dashboard",
        component: Dashboard
    },
    {
        path: "/dashboard/requisition-request",
        component: RaiseNewRequest
    },
    {
        path: "/dashboard/requisition-request-approve",
        component: ApproveNewRequest
    },
    {
        path: "/admin-users",
        component: AdminUsers
    },
    {
        path: "/admin-users/add-user",
        component: AddUser
    },
    {
        path: "/admin-users/user-details",
        component: ViewAdminUser
    },
    {
        path: "/admin-users/edit-user",
        component: AddUser
    },
    {
        path: "/reports",
        component: Reports
    },
    {
        path: "/reports/daily-cost-output-for-tally",
        component: DailyCostOpByTalleyReport
    },
    {
        path: "/reports/daily-cost-comparison-by-show",
        component: DailyCostCompByShowReport
    },
    {
        path: "/reports/weekly-cost-comparison-by-show",
        component: WeeklyCostCompByShowReport
    },
    {
        path: "/reports/daily-cost-comparison-by-vendor",
        component: DailyCostCompByVendorReports
    },
    {
        path: "/vc-users",
        component: VcUsers
    },
    {
        path: "/vc-users/add-user",
        component: AddVcUsers
    },
    {
        path: "/vc-users/edit-user",
        component: AddVcUsers
    },
    {
        path: "/vc-users/view-user",
        component: AddVcUsers
    },
    {
        path: "/ec-users",
        component: EcUsers
    },
    {
        path: "/ec-users/add-user",
        component: AddEcUsers
    },
    {
        path: "/ec-users/edit-user",
        component: AddEcUsers
    },
    {
        path: "/ec-users/view-user",
        component: AddEcUsers
    },
    {
        path: "/fc-users",
        component: FcUsers
    },
    {
        path: "/fc-users/add-user",
        component: AddFcUsers
    },
    {
        path: "/fc-users/edit-user",
        component: AddFcUsers
    },
    {
        path: "/fc-users/view-user",
        component: AddFcUsers
    },
    {
        path: "/roles",
        component: Roles
    },
    {
        path: "/roles/add-role",
        component: AddRole
    },
    {
        path: "/roles/edit-role",
        component: AddRole
    },
    {
        path: "/channels",
        component: ManageChannel
    },
    {
        path: "/channels/add-channel",
        component: AddChannel
    },
    {
        path: "/channels/edit-channel",
        component: AddChannel
    },
    {
        path: "/channels/view-channel",
        component: AddChannel
    },
    {
        path: "/VFX-Master",
        component: VfxMaster
    },
    {
        path: "/VFX-Master/add-VFX-partner",
        component: AddVfx
    },
    {
        path: "/VFX-Master/edit-VFX-partner",
        component: AddVfx
    },
    {
        path: "/serials",
        component: ManageSerials
    },
    {
        path: "/serials/add-project",
        component: AddProject
    },
    {
        path: "/serials/edit-project",
        component: AddProject
    },
    {
        path: "/serials/project-details",
        component: ProjectDetails
    },
    {
        path: "/serials/one-time-cost",
        component: OneTimeCost
    },
    {
        path: "/serials/add-fc",
        component: AddFcSerial
    },
    {
        path: "/serials/view-lock-FC",
        component: ViewLockFc
    },
    {
        path: "/serials/episodic-listing",
        component: EpisodicListing
    },
    {
        path: "/serials/episodic-listing/add",
        component: EpisodicListAdd
    },
    {
        path: "/serials/episodic-listing/edit",
        component: EpisodicListAdd
    },
    {
        path: "/serials/episodic-listing/clone",
        component: EpisodicListAdd
    },
    {
        path: "/serials/episodic-listing/assign-employee",
        component: AssignEmployee
    },
    {
        path: "/product-master",
        component: ProductMaster
    },
    {
        path: "/product-master/add-product",
        component: AddProductMaster
    },
    {
        path: "/product-master/edit-product",
        component: AddProductMaster
    },
    {
        path: "/product-master/view-product",
        component: AddProductMaster
    },
    {
        path: "/product-master/add-category",
        component: AddCategory
    },
    {
        path: "/product-master/units",
        component: ManageUnits
    },
    {
        path: "/product-master/units/add-unit",
        component: AddUnit
    },
    {
        path: "/product-master/units/edit-unit",
        component: AddUnit
    },
    {
        path: "/location",
        component: ManageLocation
    },
    {
        path: "/location/add-location",
        component: AddLocation
    },
    {
        path: "/location/edit-location",
        component: AddLocation
    },
    {
        path: "/location/location-details",
        component: ViewLocation
    },
    {
        path: "/vendors",
        component: AllVendor
    },
    {
        path: "/vendors/vendor-details",
        component: ViewVendor
    },
    {
        path: "/vendors/add-vendor",
        component: AddVendor
    },
    {
        path: "/vendors/edit-vendor",
        component: AddVendor
    },
    {
        path: "/schedules",
        component: ManageSchedule
    },
    {
        path: "/schedules/schedule-details",
        component: ScheduleDetails
    },
    {
        path: "/schedules/add-schedule",
        component: AddSchedule
    },
    {
        path: "/schedules/edit-schedule",
        component: AddSchedule
    },
    {
        path: "/schedules/add-attendance",
        component: Attendance
    },
    {
        path: "/product-requisition",
        component: ProductRequisition
    },
    {
        path: "/product-requisition/product-requisition-request",
        component: RaiseNewProductRequisition
    },
    {
        path: "/product-requisition/product-requisition-request-approve",
        component: ApproveProductRequisition
    },
    {
        path: "/manageUnits",
        component: ManageUnitSchedule
    },
    {
        path: "/manageUnits/add-unit",
        component: AddUnitSchedule
    },
    {
        path: "/manageUnits/edit-unit",
        component: AddUnitSchedule
    },
    {
        path: "/schedules/planned-reports",
        component: PlannedReport
    },
    {
        path: "/schedules/actual-reports",
        component: ActualReport
    },
    {
        path: "/schedules/final-reports",
        component: FinalCostReport
    },
    {
        path: "/user-profile",
        component: UserProfile
    },
    {
        path: "/resources",
        component: Resources
    },
    {
        path: "/reports/cost-comparison-by-show",
        component: CostComparisonReport
    },
    {
        path: "/reports/planned-actual-variance",
        component: PlannedActualVarience
    },
    {
        path: "/reports/planned-delivered-variance",
        component: PlannedDeliveredVarience
    },
    {
        path: "/reports/daily-comparison-detailed",
        component: DailyComparisonDetailed
    },
    {
        path: "/reports/daily-comparison-varience",
        component: DailyComparisonWip
    },
    {
        path: "/reports/monthly-plan-vs-actual-by-vendor",
        component: MonthlyCostCompByVendor
    },
]

export const PublicRouteComponents = [
   
    {
        path: "/login",
        component: Login
    },
    {
        path: "/forgot-password",
        component: ForgotPassword
    },
    {
        path: "/verify-mail",
        component: CheckMail
    },
    {
        path: "/reset-passowrd",
        component: ResetPassword
    },
    {
        path: "/password-reset-success",
        component: Success
    },
]