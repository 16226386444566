// loaderSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LoaderState {
  loading: boolean;
  activeRequests: number;
}

const initialState: LoaderState = {
  loading: false,
  activeRequests: 0,
};

const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.activeRequests += 1;
      state.loading = true;
    },
    stopLoading: (state) => {
      state.activeRequests -= 1;
      if (state.activeRequests <= 0) {
        state.loading = false;
      }
    },
  },
});

export const { startLoading, stopLoading } = loaderSlice.actions;
export default loaderSlice.reducer;
