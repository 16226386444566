import {
  FetchArgs,
  BaseQueryFn,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { getIdToken } from "firebase/auth";
import { notifications } from "@mantine/notifications";
import { store } from "../store";
import { loader } from "./staticApi";
import { auth } from "../../constants/firebaseConfig";
import { startLoading, stopLoading } from "./loaderSlice";

export const BaseUrl = `${window.location.origin}/api/v1/`
// export const BaseUrl = `https://oneview.swastikproductions.com/api/v1/`
// export const BaseUrl = `https://swastik.gaggle.online/api/v1/`

const baseQuery = fetchBaseQuery({
  baseUrl: BaseUrl,
  prepareHeaders: async (headers) => {
    const Token: any = sessionStorage.getItem("Swastik_login");
    const email = sessionStorage.getItem('Swastik_login_email');
    // headers.set("Content-type", "application/json");
    if (Token && Token !== "undefined") {
      headers.set("Authorization", "Bearer " + Token);
    }
    if (email) {
      headers.set("email", email);

    }
    return headers;
  },
});

const customFetchBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  store.dispatch(startLoading()); // Increase request count & show loader

  const result = await baseQuery(args, api, extraOptions);

  store.dispatch(stopLoading()); // Decrease request count & hide loader if no pending requests

  const tokenExpired: any = result?.error?.data;

  if (api.endpoint === "login") {
    sessionStorage.setItem("Swastik_login", "Swastik_login_success");
  }

  if ((result.error as FetchBaseQueryError)?.status === 302) {
    store.dispatch(stopLoading());
  }

  if (tokenExpired?.error === "Invalid token." || tokenExpired?.detail === "Invalid token.") {
    try {
      const user = auth.currentUser;
      if (user) {
        const newToken = await getIdToken(user, true);
        sessionStorage.setItem("Swastik_login", newToken);

        // Retry the failed request with the new token
        const retryResult = await baseQuery(args, api, extraOptions);
        return retryResult;
      } else {
        notifications.show({
          title: "Session Expired",
          message: "User is not logged in. Please log in again.",
          color: "red",
        });
      }
    } catch (error) {
      console.error("Failed to refresh token:", error);
      notifications.show({
        title: "Authentication Error",
        message: "Failed to fetch data.. Please log in again.",
        color: "red",
      });
    }
  }

  if (result?.error?.status === "FETCH_ERROR") {
    notifications.show({
      title: "Error",
      message: "Failed to fetch data.",
      color: "red",
    });
  }

  return result;
};


export const baseApi = createApi({
  reducerPath: "baseApiReducer",
  baseQuery: customFetchBaseQuery,
  endpoints: () => ({}),
  tagTypes: [
    "VcUser",
    "fcUser",
    "ecUser",
    "adminUser",
    "dashboard",
    "UserRole",
    "channel",
    "vfx",
    "serials",
    "productMaster",
    "units",
    "category",
    "location",
    "vendor",
    "schedules",
    "scheduleUnits",
    "misc",
  ],
});
